import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AuthentificationService } from '../../shared/services/http/authentication/authentifications.service';
import { UtilsService } from '../../shared/services/utils.service';
import { LocalStorageService } from 'src/app/shared/services/storage/localstorage.service';
import { AuthService } from 'src/app/shared/services/http/authentication/auth.service';




type UserFields = 'email' | 'password';
type FormErrors = { [u in UserFields]: string };

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public newUser = false;
  // public user: firebase.User;
  public loginForm: FormGroup;
  public formErrors: FormErrors = {
    'email': '',
    'password': '',
  };
  title = "Connexion"
  public errorMessage: any;
  isLoading: boolean = false;
  isPasswordShown: boolean = false;
  isPasswordVisible: boolean = false;
  email: string = '';
  password: string = '';
  submitted: boolean = false;

  constructor(private fb: FormBuilder, private authService: AuthService,
    private router: Router, private dataservice: AuthentificationService,
    private utils: UtilsService,
    private localStorage: LocalStorageService) {
    this.loginForm = fb.group({
      email: "",
      password: ""
    });
  }

  ngOnInit() {
  }


  login() {

    console.log("this.email",this.email);
    console.log("password",this.password);
    
    this.submitted = true;

    if (this.email ==="" || this.password ==="") {


      let error: string = "Veuiller rensigner tous les champs";
      this.utils.notifyErrorMessage(error, "Connexion"); return;
    }

    if (this.email && this.password) {
      if (this.email === 'admin' && this.password === 'admyemi') {
        const requestData = {
          login: this.email,
          password: this.password
        };
        this.localStorage.saveUserConnected(requestData);
        this.router.navigate(['/dashboard/default']);
      }else{

        this.utils.notifyErrorMessage("Le login ou le mot de passe ne correspond pas",'connexion'); return;

      }
    }
  }


  //  }
  // login() {

  //   const requestData ={
  //     "login":"admin@gmail.com",
  //     "pasword":"admin"
  //   }
  //   this.router.navigate(['/dashboard/default']);
  //        let test = this.localStorage.saveUserConnected(requestData);

  //   // this.isLoading = true;
  //   // this.dataservice.signIn(this.loginForm.value['email'], this.loginForm.value['password'])
  //   // .subscribe((result) => {
  //   //   this.isLoading = false;
  //   //   if (result === undefined) {
  //   //     this.isLoading = false;
  //   //     this.utils.notifyErrorMessage("Une erreur est survenue.veuillez patienter puis réessayer dans quelques minutes.", this.title);
  //   //   }
  //   //   if (result.status === "error") {
  //   //     this.isLoading = false;
  //   //     let error: string = result.message;
  //   //     this.utils.notifyErrorMessage(error, this.title);
  //   //     return;
  //   //   }
  //   //   else {
  //   //     //console.log(result);

  //   //   }
  //   // }, (error: any) => {
  //   //   this.isLoading = false;
  //   //   //let errorMsg: string = "Vos paramètres de connexion sont incorrects.";
  //   //   let errorMsg:string = error.error.message
  //   // this.utils.notifyErrorMessage(errorMsg, this.title);
  //   // return;
  //   // });
  // }


  // this.authService.login(this.collaborateur).subscribe((result) => {
  //   this.loaderService.stop();
  //   if (result === undefined) {
  //    let error: string = "Une erreur est survenue. Si ce message persiste, veuillez patienter puis réessayer dans quelques minutes.";
  //     this.utils.notifyErrorMessage(error, "Connexion"); return;
  //   }
  //   if (result.status === "error") {
  //     let error: string = result.message;
  //     this.utils.notifyErrorMessage(error, "Connexion");
  //     return;
  //   }
  //   else
  //   {
  //    let userLogin : any = result;
  //     //console.log(result)
  //     //save user
  //     //let user : any = { login : userLogin.email, motDePasse : userLogin.password, role : userLogin.role.code, nom : userLogin.nom, prenom : userLogin.prenom, tel : userLogin.tel, sexe : userLogin.sexe };
  //     ////console.log(user);
  //     let test = this.localStorage.saveUserConnected(result);
  //     //console.log(test)
  //     setTimeout(() => { this.loaderService.stop(); }, 5000);
  //     this.router.navigate(["/admin/accueil"]);
  //   }
  // }, (error: any) => {
  //   let errorMsg: string = error.message;
  //   this.utils.notifyErrorMessage(errorMsg, "Connexion");
  //   return;
  // })
  //}
  //get login
  getLogin() {
    this.isLoading = true
    this.dataservice.getAuth().subscribe((data) => {
      //console.log(data);
      this.isLoading = false
      // this.listeItems = data;/
    });
  }

  //toogle button
  toogleState() {
    this.isPasswordShown = !this.isPasswordShown;
  }//end toogleState

  //set classes
  getType() {
    if (this.isPasswordShown) {
      return "text";
    } else {
      return "password";
    }
  }//end setMyClasses

  //toogle password View
  tooglePasswordView(): void {
    this.isPasswordVisible = !this.isPasswordVisible;
  }//fin tooglePasswordView

  //get get input type
  getInputType(): string {
    if (this.isPasswordVisible) { return "text"; } else { return "password"; }
  }//fin getInputType

  //get icon visible or not
  getIconValue(): string {
    if (this.isPasswordVisible) { return "fa fa-eye"; } else { return "fa fa-eye-slash"; }
  }//fin getIconValue

}
