import { Routes } from '@angular/router';

export const content: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../components/dashboard/dashboard.module').then(m => m.DashboardModule),
    // data: {
    //   breadcrumb: "Dashboard"
    // }
  },
  {
    path: 'offres',
    loadChildren: () => import('../../components/operations/operations-module').then(m => m.OffresModule),
    data: {
      //breadcrumb: "Offres"
    }
  },
  {
    path: 'parametres',
    loadChildren: () => import('../../components/parametres/parametres.module').then(m => m.ParametresModule),
    data: {
      //breadcrumb: "Paramètres"
    }
  },
  {
    path: 'vehicules',
    loadChildren: () => import('../../components/vehicules/vehicule.module').then(m => m.VehiculesModule),
    data: {
      //breadcrumb: "Attributions"
    }
  },
  {
    path: 'statistiques',
    loadChildren: () => import('../../components/statistiques/statistiques.module').then(m => m.StatistiqueModule),
    data: {
      //breadcrumb: "Statistiques"
    }
  },
  
];