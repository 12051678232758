import { Injectable } from '@angular/core';
// import { ChatDB } from '../data/chat/chat';
// import { ChatUsers, chat } from '../model/chat.model';
import { Observable, Subscriber } from 'rxjs';
import { map, filter, scan } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { CanActivate, Router } from '@angular/router';
var today = new Date().toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

@Injectable({
  providedIn: 'root'
})
export class AuthentificationService implements CanActivate {

  public observer: Subscriber<{}>;
 

  constructor(private http: HttpClient,private router: Router) { }

  /*------------------START GET METHODE-------------------*/ 


   signIn(email: string, password: string): Observable<any> {
    //auth/login
    let item = {
      email : email,
      password : password
    }
    
    return this.http.post(environment.apiUrl+'auth/login' , item );
  }


   // get type intervention
   getAuth(): Observable<any>{
    return this.http.get(environment.apiUrl + '');
   }
   resetPasssword(item): Observable<any>{
    return this.http.put(environment.apiUrl + 'auth/reset-password' , item);
   }

  

   canActivate(): boolean {
    // Vérifiez ici si l'utilisateur est authentifié
    // Si l'utilisateur est authentifié, retournez true, sinon redirigez-le vers la page de connexion et retournez false

    // Exemple simple pour l'instant
    const isAuthenticated = true; // Remplacez ceci par votre logique d'authentification
    if (!isAuthenticated) {
      this.router.navigate(['/login']); // Redirigez vers la page de connexion si l'utilisateur n'est pas authentifié
    }
    return isAuthenticated;
  }

  

}
