import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Injectable()
export class UtilsService {

    constructor(private toastr: ToastrService,) { }


     // notifie une erreur
    notifyErrorMessage(message : string, title: string,) {
        this.toastr.error(message, title);
    }//end notifyErrorMessage

    // notifie une information
    notifyInfoMessage(message : string, title: string,) {
        this.toastr.info(message, title);
    }//end notifyInfoMessage

    // notifie un succes
    notifySuccessMessage(message : string, title: string,) {
        this.toastr.success(message, title);
    }//end notifySuccessMessage

      // demande  unen confirmation
      askConfirmation(message : string,) {
        return confirm(message);
    }//end askConfirmation


    sansAccent(text:string){
        var accent = [
            /[\300-\306]/g, /[\340-\346]/g, // A, a
            /[\310-\313]/g, /[\350-\353]/g, // E, e
            /[\314-\317]/g, /[\354-\357]/g, // I, i
            /[\322-\330]/g, /[\362-\370]/g, // O, o
            /[\331-\334]/g, /[\371-\374]/g, // U, u
            /[\321]/g, /[\361]/g, // N, n
            /[\307]/g, /[\347]/g, // C, c
        ];
        var noaccent = ['A','a','E','e','I','i','O','o','U','u','N','n','C','c'];
         
      
        for(var i = 0; i < accent.length; i++){
            text = text.replace(accent[i], noaccent[i]);
        }
         
        return text;
    }
    // genere une url darticle a partir du titre
    generateUrlFromTitle(title : string) {

        let getTitle: any = "";
        let url: any = "";


        //mettre le titre en minuscule
      url=title.toLowerCase();
        //remplacer chaque espace par un tiret 
        url= url.replace(" ", '-')
        //remplacer les lettres accentuees par leur equivalent non accentuees
        url=this.sansAccent(url);
    //  url=  document.write(url.iso2uni());
        //var y=document.createElement('span');
       // y.innerHTML=url;
      //  url=y.innerHTML;
        //limiter le nombre de caracteres a 50 au maximum
      url= url.substr(0,50);

       return url;
    }//end generateUrlFromTitle
 // affiche un message de succès
 displaySuccessMessage(data : string, router: Router,) {
    Swal.fire('MyWorld Web',
    data, 'success');
  }//end displaySuccessMessage

  displayErrorMessage(message : string, router: Router,) {
    Swal.fire({
      customClass: { container: 'myCustomSwal' },
      icon: 'error',
      title: message
    });
  } //end displayErrorMessage
}