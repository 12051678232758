<section class="h-100 gradient-form">
	<div class="container py-5 h-100">
	  <div class="row d-flex justify-content-center align-items-center h-100">
		<div class="col-xl-10">
		  <div class="card rounded-3 text-black">
			<div class="row g-0">
			  <div class="col-lg-6">
				<div class="card-body p-md-5 mx-md-4">
  
				  <div class="text-center">
					<img src="../../../assets/logo.png"
					  style="width: 185px;" alt="logo">
				  </div>
  
				  <form>
					<p class="d-flex justify-content-center">Veuillez vous connecter</p>
  
					<div data-mdb-input-init class="form-outline mb-4">
						<label class="form-label" for="form2Example11">Adresse E-mail</label>
					  <input [(ngModel)]="email" name="email" type="email" id="form2Example11" class="form-control"
						placeholder="admin@gmail.com" />
					</div>
  
					<div data-mdb-input-init class="form-outline mb-4">
						<label class="form-label" for="form2Example22">Mot de passe
						</label>
					  <input [(ngModel)]="password" name="password" type="password" id="form2Example22" class="form-control" placeholder="mot de passe"/>
					</div>
  
					<div class="text-center pt-1 mb-5 pb-1">
					  <button (click)="login()" data-mdb-button-init data-mdb-ripple-init class="btn btn-primary btn-block fa-lg gradient-custom-2 mb-3" type="button">Login</button>
					</div>
				  </form>
  
				</div>
			  </div>
			  <div class="col-lg-6 d-flex align-items-center gradient-custom-2">
				<div class="text-white px-3 py-4 p-md-5 mx-md-4">
				  <h4 class="mb-4">Espace Yemi Marchand</h4>
				  <p class="small mb-0">Bienvenue sur Espace Yemy Marchand</p>
				</div>
			  </div>
			</div>
		  </div>
		</div>
	  </div>
	</div>
  </section>